import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { first } from 'rxjs/operators';
import { RptManagerService } from '../app/core/services/rpt-manager/rpt-manager.service'
import { AppConstants } from './core/intefaces/constants';
import { Report } from './core/intefaces/rpt-manager/i-report';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'HtH - Grupo Vargas';
  ApiUrl= environment.ApiUrl;
  loading: boolean = false;
  reports: Report[] = [];

  constructor(public router: Router,
    private cd: ChangeDetectorRef,
    private rptManagerService: RptManagerService,
    private MatPaginatorIntl: MatPaginatorIntl
  ) {
    MatPaginatorIntl.itemsPerPageLabel = "Items por página";
    MatPaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length == 0 || pageSize == 0) { return `0 of ${length}`; }

      length = Math.max(length, 0);

      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
          Math.min(startIndex + pageSize, length) :
          startIndex + pageSize;

      return `${startIndex + 1} - ${endIndex} de ${length}`;
    }
  }

  ngOnInit() {
    // this.router.navigate(['']);  //redirect other pages to homepage on browser refresh
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }


}
