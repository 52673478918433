import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Sidebar } from 'ng-sidebar';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../core/intefaces/constants';
import { AppService } from '../core/services/app.service';
import { AuthenticationService } from '../core/services/authentication.service';
import { CompanyService } from '../core/services/company.service';
import { GlobalService } from '../core/services/global.service';
import { SidebarService } from '../core/services/sidebar.service';
// models
import { Menu, SubMenu } from './../core/intefaces/menu';


@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit, OnDestroy {
  @ViewChild('sideBar') sidebar!: ElementRef<Sidebar>;

  _menu: Menu[] = [];
  _opened: boolean = false;
  _sidebarMode: any = 'push';
  menu: any;
  hiddenMenu!: boolean
  constructor(
    private router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public appService: AppService,
    private sidebarService: SidebarService,
    private authService: AuthenticationService,
    private companyService: CompanyService,
    private globalService: GlobalService,
  ) {
    this.sidebarService.toggler.subscribe((toggle) => {
      this._opened  = !toggle;
    });
  }

  ngOnInit(): void {
    console.log('ngOnInit pages');
    this.hiddenMenu = this.router.url === '/'
    this.router.events.subscribe(path => {
      if(path instanceof NavigationEnd){
        if(path.url === '/') {
          this.hiddenMenu = true
        } else {
          this.hiddenMenu = false
        }
      }
    })
  }

  ngOnDestroy(): void {
  }

  public _toggleSidebar() {
    this._opened = !this._opened;
  }

  onClickSubMenuOption(url: SubMenu) {
    this.router.navigateByUrl(url.url);
  }

  onClickMenuOption(option: Menu) {
    this.appService.Data.menu.forEach((x) => {if(x != option) x.active = false});
    this._menu.forEach((x) => (x.active = false));
    if (option.name === 'Salir') {
      this.authService.logout();
      // window.location.href = ('https://gvhthtest.clavisco.com/#/Login');
      window.location.href = `${environment.UiUrl}Login`;
    } else {
      option.active = !option.active;
    }
    if (option.url !== '' && option.url !== 'Reports-Print') {
      this.router.navigateByUrl('/' + option.url, {state: {prevPage: option.url}});
    }
    if (option.url !== '' && option.url === 'Reports-Print') {
      this.appService.Data.Reports.length = 0;
      let index = this.appService.Data.menu.findIndex(x => x.url === 'Reports-Print');
        this.appService.Data.menu[index].submenu = [];
      this.getReports();
    }
  }

  getReports() {
    console.log('getReport');
    let reports = [];

    if (!AppConstants.KeyReportManager) {
      this.companyService
        .GetKeyReportManager()
        .pipe(first())
        .subscribe((response) => {
          if (response.Result) {
            AppConstants.KeyReportManager = response.Data;
            this.globalService.getReports();
            // this.getReportUsers();
            // this.getReports();
          } else {
            // this.alertService.errorAlert(
            //   "Ocurrió un error obteniendo la configuración de reportes de la compañía"
            // );
          }
        });
    } else {
      this.globalService.getReports();
      // this.getReportUsers();
      // this.getReports();
    }
  }



}
