import { Routes, RouterModule, PreloadAllModules, Route  } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AuthGuard } from './core/guards/auth.guard';

export const routes: Routes = [
    { 
        path: '', 
        canActivateChild: [AuthGuard],
        component: PagesComponent, children: [
            { path: 'SelectCompany', loadChildren: () => import('./pages/select-company/select-company.module').then(m => m.SelectCompanyModule),  },
            { path: 'SelectDocuments', loadChildren: () => import('./pages/select-documents/select-documents.module').then(m => m.SelectDocumentsModule) },
            { path: 'SearchPruchaseOrders', loadChildren: () => import('./pages/search-purchase-order/search-purchase-order.module').then(m => m.SearchPurchaseOrderModule) },
            { path: 'Lots', loadChildren: () => import('./pages/lots/lots.module').then(m => m.LotsModule) },
            { path: 'Reports', loadChildren: () => import('./pages/reports/rpt-manager/report-manager.module').then(m => m.ReportManagerModule) },
            { path: 'Reports-Print/:reportId', loadChildren: () => import('./pages/reports/rpt-manager-print/rpt-manager-print.module').then(m => m.RptManagerPrintModule) },
            { path: 'ConfigCtaBanks', loadChildren: () => import('./pages/config-cta-banks/config-cta-banks.module').then(m => m.ConfigCtaBanksModule) },
            { path: 'ConfigEmails', loadChildren: () => import('./pages/config-emails/config-emails.module').then(m => m.ConfigEmailsModule) },
            { path: 'ConfigPerms', loadChildren: () => import('./pages/config-perms/config-perms.module').then(m => m.ConfigPermsModule) },
            { path: 'ConfigCompany', loadChildren: () => import('./pages/config-company/config-company.module').then(m => m.ConfigCompanyModule) },
            { path: 'SAPCredentials', loadChildren: () => import('./pages/config-sap-credentials/config-sap-credentials.module').then(m => m.ConfigSapCredentialsModule) },
            { path: 'Home/:UserId', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
            { path: 'Home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), },
            { path: 'Login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
            { path: '', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule), pathMatch: 'full' },       
            //{ path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
        ],
    },
    { path: '**', component: NotFoundComponent }
];

export const AppRoutingModule: ModuleWithProviders<Route> = RouterModule.forRoot(routes, {
//    preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
   useHash: true,
   onSameUrlNavigation: 'reload'
});