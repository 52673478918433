import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { first } from "rxjs/operators";
//import { Globals } from "src/app/globals";
// Models
import { BaseResponse } from "src/app/core/intefaces/response";
import { AppConstants } from "src/app/core/intefaces/constants"
import { Email } from "src/app/core/intefaces/rpt-manager/i-email"
import { Parameter } from "src/app/core/intefaces/rpt-manager/i-parameter"
import { Report } from "src/app/core/intefaces/rpt-manager/i-report"


@Injectable({
  providedIn: "root",
})
export class RptManagerService {
  //constructor(private http: HttpClient, private globals: Globals) {}
  constructor(private http: HttpClient) {}

  SaveReportFile(report: File) {    
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    
    const httpOptions = {
      headers: headers
  };
    let formData = new FormData();
    if (report && report.size > 0) {
      formData.append("report", report);
    }
    
    return this.http.post<BaseResponse>(
      `${AppConstants.RPTMANAGER_URL}api/Reports/SaveReportFile?companyKey=${AppConstants.Company}&appKey=${AppConstants.KeyReportManager}`,
      formData,httpOptions
    );
  }

  HandlePostOrPutReport(report: Report) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token.access_token}`
    });

    if (report.Id > 0) {
      return this.http.put<any>(
        `${AppConstants.RPTMANAGER_URL}api/Reports/PutReport`,
        report,
        { headers }
      );
    } else {
      return this.http.post<any>(
        `${AppConstants.RPTMANAGER_URL}api/Reports/PostReport`,
        report,
        { headers }
      );
    }
  }

  GetReports() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.get<any>(
      `${AppConstants.RPTMANAGER_URL}api/Reports/GetReports?companyKey=${AppConstants.Company}&appKey=${AppConstants.KeyReportManager}`,
      { headers }
    );
  }

  GetParameters(reportId: number) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.get<any>(
      `${AppConstants.RPTMANAGER_URL}api/Parameter/GetParameters?reportId=${reportId}`,
      { headers }
    );
  }

  PrintReport(parameters: Parameter[], reportId: number) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.post<any>(
      `${AppConstants.RPTMANAGER_URL}api/Reports/PrintReport?reportId=${reportId}`,
      parameters,
      { headers }
    );
  }

  SendEmail(emailInfo: Email, reportId: number) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.post<any>(
      `${AppConstants.RPTMANAGER_URL}api/Reports/SendEmail?reportId=${reportId}`,
      emailInfo,
      { headers }
    );
  }

  DownloadFile(reportId: number) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.get<any>(
      `${AppConstants.RPTMANAGER_URL}api/Reports/DownloadFile?reportId=${reportId}`,
      { headers }
    );
  }

  GetReportUsers() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.get<any>(
      `${AppConstants.RPTMANAGER_URL}api/ReportUser/GetReportUsers`,
      { headers }
    );
  }

  DeleteReport(reportId: number) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.delete<any>(
      `${AppConstants.RPTMANAGER_URL}api/Reports/DeleteReport?reportId=${reportId}`,
      { headers }
    );
  }

  GetApplicationId(appKey: number): Promise<number> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token.access_token}`
    });

    return new Promise((resolve, reject) => {
      this.http
        .get<any>(
          `${AppConstants.RPTMANAGER_URL}api/Reports/GetApplicationId?appKey=${appKey}`,
          { headers }
        )
        .pipe(first())
        .subscribe(
          (response) => {
            if (response.Result) {
              resolve(response.Data);
            } else {
              reject(null);
            }
          },
          () => {
            reject(null);
          }
        );
    });
  }

  AddReportsToMenu(reports: Report[], menu: any[], defaultMenuOption: any) {
    reports.forEach((report, index) => {
      const layoutConfig = JSON.parse(report.LayoutConfig);

      if (
        // (layoutConfig.Device === 1 || layoutConfig.Device === 3) &&
        // this.hasPerm(layoutConfig.Perm)
        (layoutConfig.Device === 1 || layoutConfig.Device === 3)
      ) {
        let targetNode: any;

        // if (layoutConfig.Position) {
        //   targetNode = this.TargetMenuOption(layoutConfig.Position, menu);
        // } else {
        //   targetNode = defaultMenuOption;
        // }

        this.AddReportToMenu(report, targetNode, index);
      }
    });
  }

  // private TargetMenuOption(layoutConfig: any, menu: any[]) {
  //   if (!layoutConfig || !menu) return null;

  //   let targetNode = menu.find((x) => x.Name == layoutConfig.Name);

  //   if (layoutConfig.GoesTo && targetNode)
  //     return this.TargetMenuOption(layoutConfig.GoesTo, targetNode.Children);
  //   else return targetNode;
  // }

  private AddReportToMenu(report: Report, menuOption: any, index: number) {
    if (!menuOption) return;
    if (!menuOption.Children) menuOption.Children = [];

    menuOption.Children.push({
      Name: report.DisplayName,
      Route: `print-report/${report.Id}`,
      Icon: "",
      Children: [],
      Selected: false,
      Type: 2,
      Visible: true,
      RequiredPerm: "",
    });
  }

  // hasPerm(requiredPerm: string) {
  //   let perms = this.globals.perms;
  //   if (!perms) return false;
  //   if (!requiredPerm) return true;

  //   return perms.some((x: { Name: string; }) => x.Name === requiredPerm);
  // }
}
