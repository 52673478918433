import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DataStorageService } from 'src/app/core/services/data-storage.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  private AUTH_HEADER = "Authorization";

  constructor(
    private spinner: NgxSpinnerService,
    private dataStorageService: DataStorageService,
    private authService: AuthenticationService
    ) {
      console.log('appinterceptor');
  }

  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {    
    this.spinner.show();

    if (!req.headers.has('Content-Type')) {

      if(req.url.includes('api/Report/GetPurchaseOrderReport')){
        req = req.clone({
          headers: req.headers.set('responseType', 'blob')
        });
      }
      else if(req.url.includes('api/Reports/SaveReportFile')){        
      } 
      else {
        req = req.clone({
          headers: req.headers.set('Content-Type', 'application/json')
        });
      }
    }

    req = this.addAuthenticationToken(req);


    return next.handle(req).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.spinner.hide();
      }
      return event;
    }),
      catchError((error: HttpErrorResponse) => {
        const started = Date.now();
        const elapsed = Date.now() - started;
        console.log(`Request for ${req.urlWithParams} failed after ${elapsed} ms.`);
        this.spinner.hide();
        return throwError(error.error.error_description || error.error.error || error.error.Message || error.statusText);
        // return throwError(error);
      })
    );

  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    // If we do not have a token yet then we should not set the header.
    // Here we could first retrieve the token from where we store it.
    const TOKEN = JSON.parse(this.dataStorageService.getStorage('HTHCurrentUser'));
    if (!TOKEN) {
      return request;
    }
    
    if (!this.authService.isAuthenticated()) {
      this.authService.logout();
      throw new Error('No esta autenticado');
    }
    let headers = request.headers.set(`Authorization`, `Bearer ${TOKEN.access_token}`);
    request = request.clone({headers});
    return request;
    // return request.clone({
    //   headers: request.headers.set(this.AUTH_HEADER, `Bearer ${TOKEN.access_token}`)
    // });
  }

  // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

  //   this.spinner.show();

  //   return next.handle(req).pipe(map((event: HttpEvent<any>) => {
  //     if (event instanceof HttpResponse) {
  //       this.spinner.hide();
  //     }
  //     return event;
  //   }),
  //     catchError((error: HttpErrorResponse) => {
  //       const started = Date.now();
  //       const elapsed = Date.now() - started;
  //       console.log(`Request for ${req.urlWithParams} failed after ${elapsed} ms.`);
  //       // ;
  //       return throwError(error);
  //     })
  //   );

  // }
}