import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

////////
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';

import { OverlayContainer, Overlay } from '@angular/cdk/overlay';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';
import { menuScrollStrategy } from './theme/utils/scroll-strategy';

import { SharedModule } from './shared/shared.module';
import { PagesComponent } from './pages/pages.component';

import { AppInterceptor } from './theme/utils/app-interceptor';
import { FooterComponent } from './theme/components/footer/footer.component';

import { SidebarModule } from 'ng-sidebar';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { HeaderComponent } from './theme/components/header/header.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MantParameterOptionComponent } from './pages/reports/rpt-manager/mant-parameter-option/mant-parameter-option.component';

@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    FooterComponent,
    NotFoundComponent,
    HeaderComponent,
    MantParameterOptionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    SharedModule,
    SidebarModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: MAT_MENU_SCROLL_STRATEGY, useFactory: menuScrollStrategy, deps: [Overlay] },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
