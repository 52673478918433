<!-- <ng-template #modalParameterOptions let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Opciones válidas</h4>
    </div>
    <div class="modal-body p-0">
      <div class="container-fluid mt-2">
        <form class="row" [formGroup]="parameterValueForm">
          <div class="col-sm-5">
            <div class="form-group row">
              <label class="col-form-label col-3">Nombre</label>
              <div class="col-9">
                <input
                  type="text"
                  formControlName="Key"
                  class="form-control form-control-sm"
                />
              </div>
            </div>
          </div>
          <div class="col-sm-5">
            <div class="form-group row">
              <label class="col-form-label col-3">Valor</label>
              <div class="col-9">
                <input
                  type="text"
                  formControlName="Value"
                  class="form-control form-control-sm"
                />
              </div>
            </div>
          </div>
          <div class="col text-center">
            <button
              class="btn btn-sm"
              (click)="onClickAddParameterValidValue()"
              [disabled]="parameterValueForm.invalid"
            >
              <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
          </div>
        </form>
      </div>
      <div class="container-fluid">
        <div class="row" *ngIf="parameterOptionsModal.length === 0">
          <div class="col-12">
            Aún no se han agregado opciones válidas para el parámetro...
          </div>
        </div>
        <div
          class="row"
          *ngFor="let validValue of parameterOptionsModal; index as i"
        >
          <div class="container-fluid">
            <div class="input-group mb-2">
              <input
                type="text"
                class="form-control form-control-sm"
                [value]="validValue.Key + ': ' + validValue.Value"
                disabled
              />
              <div class="input-group-append">
                <button
                  class="btn btn-sm btn-light"
                  type="button"
                  (click)="onClickDeleteValidValue(i)"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="container-fluid">
        <div class="row justify-content-end">
          <button
            class="btn btn-sm btn-light mx-1"
            (click)="dismissModal(true)"
          >
            Volver
          </button>
        </div>
      </div>
    </div>
  </ng-template> -->

<h1 mat-dialog-title>Opciones válidas</h1>
<div mat-dialog-content>
  <div class="divider mt-2"></div>
  <div fxLayout="column" fxLayoutAlign="space-around">

    <div fxFlex="100" fxFlex.gt-sm="100" class="px-1 pt-2" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <form class="row" [formGroup]="parameterValueForm">
        <div fxFlex="100" fxFlex.gt-sm="45" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="Key">
            <mat-error *ngIf="parameterValueForm.controls.Key.errors?.required">
              El nombre es requerido.
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="45" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Valor</mat-label>
            <input matInput formControlName="Value">
            <mat-error *ngIf="parameterValueForm.controls.Value.errors?.required">
              El valor es requerido.
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="10" class="px-1 text-center" ngClass.sm="mt-1" ngClass.xs="mt-1">
          <div class="w-100 py-2">
            <button class="m-1" mat-raised-button color="primary" type="button"
              (click)="onClickAddParameterValidValue()" [disabled]="parameterValueForm.invalid">
              <mat-icon aria-hidden="false" aria-label="Example add icon" class="material-icons-outlined">add</mat-icon>
            </button>
          </div>

        </div>
      </form>
      <!-- <div class="container-fluid">
      <div class="row" *ngIf="parameterOptionsModal.length === 0">
        <div class="col-12">
          Aún no se han agregado opciones válidas para el parámetro...
        </div>
      </div>
      <div class="row" *ngFor="let validValue of parameterOptionsModal; index as i">
        <div class="container-fluid">
          <div class="input-group mb-2">
            <input type="text" class="form-control form-control-sm" [value]="validValue.Key + ': ' + validValue.Value"
              disabled />
            <div class="input-group-append">
              <button class="btn btn-sm btn-light" type="button" (click)="onClickDeleteValidValue(i)">
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="space-around">

    <div fxFlex="100" fxFlex.gt-sm="100" class="px-1 pt-2" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <div *ngIf="parameterOptionsModal.length === 0">
        <div class="w-100">
          Aún no se han agregado opciones válidas para el parámetro...
        </div>
      </div>
    </div>

    <div fxFlex="100" fxFlex.gt-sm="100" class="px-1 pt-2" ngClass.sm="mt-1" ngClass.xs="mt-1">

      <div *ngFor="let validValue of parameterOptionsModal; index as i">
        <mat-form-field class="w-100">
          <mat-placeholder>Nombre:Valor</mat-placeholder>
          <input matInput type="text" [value]="validValue.Key + ': ' + validValue.Value" readonly>
          <button mat-button matSuffix mat-stroked-button aria-label="delete" type="button" (click)="onClickDeleteValidValue(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

  </div>
  <div class="divider mt-2"></div>

</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="parameterOptionsModal" cdkFocusInitial>Volver</button>
</div>