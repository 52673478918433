import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { DataStorageService } from 'src/app/core/services/data-storage.service';
import { SectionService } from 'src/app/core/services/section.service';
import { SidebarService } from 'src/app/core/services/sidebar.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  sidebarState: boolean;
  selectedCompany: string = '';

  constructor(
    private sidebarService: SidebarService,
    private router: Router,
    public sectionService: SectionService,
    private dataStorageService: DataStorageService,
    private authService: AuthenticationService
  ) {
    this.sidebarState = false;
    this.authService.sidebarVisibilityChange.subscribe((value) => {
      let currentUser = JSON.parse(
        this.dataStorageService.getStorage('HTHSelectedCompany')
      );
      if (currentUser) {
        this.selectedCompany = currentUser[0].DBName;
      }
      this.toggleSidebar();
    });
    this.toggleSidebar();
    this.router.events.subscribe((path) => {
      if (path instanceof NavigationStart) {
        if (path.url === '/') {
          this.sidebarService.toggler.next(true);
        }
      }
    });
  }

  ngOnInit(): void {
    let currentUser = JSON.parse(
      this.dataStorageService.getStorage('HTHSelectedCompany')
    );
    if (currentUser) {
      this.selectedCompany = currentUser[0].DBName;
    }
    this.sidebarService.toggler.next(this.sidebarState);    
  }

  toggleSidebar() {
    this.sidebarState = !this.sidebarState;
    this.sidebarService.toggler.next(this.sidebarState);
  }
}
