import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IToken } from '../intefaces/i-token';
import { Permission } from '../intefaces/permission';
import { PermsModel } from '../intefaces/perms';

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {

  private localStorageS;

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor() {
    this.localStorageS = localStorage;
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(this.getStorage('HTHSelectedCompany')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public deleteCurrentUserValue(): any {
    this.currentUserSubject.next(null);
  }


  setStorage(name: string, obj: any): void {
    this.localStorageS.setItem(name, JSON.stringify(obj));
  }

  deleteStorage(name: string): void {
    this.localStorageS.removeItem(name);
  }

  getStorage(name: string): any {
    return this.localStorageS.getItem(name);
  }

  // NUEVO POR EL LOGIN NUEVO
  setCurrentSession(currentUser: any): void {
    this.localStorageS.setItem('currentUser', JSON.stringify(currentUser));
  }

  GetCurrentSession(): string | null {
    return this.localStorageS.getItem('currentUser');
  }

  GetPermissionCurrentSession(): PermsModel[] {
    const token: IToken = (JSON.parse(this.localStorageS.getItem('currentUser')!) as IToken)
    return JSON.parse(token.Permisos) as PermsModel[]
  }

  getToken() {
    return JSON.parse(this.localStorageS.getItem('currentUser') as string);
  }
  
  GetToken(): void  {
    return JSON.parse(this.localStorageS.getItem('currentUser') as string).access_token;
  }

  SetPermissions(_permissions: Permission[]): void {
    this.localStorageS.setItem('Perms', JSON.stringify(_permissions));
  }

  GetPermissions(): Permission[] {
    const PERMISSIONS = JSON.parse(this.localStorageS.getItem('Perms') as string) as Permission[];
    if (PERMISSIONS !== null) return PERMISSIONS;
    return [];
  }

  GetThirdPartyToken(): string {
    return JSON.parse(this.localStorageS.getItem('thirdParty_token') as string).access_token;
  }

  SetThirdPatyToken(_thirdParty: string): void {
    this.localStorageS.setItem('thirdParty_token', JSON.stringify(_thirdParty));
  }

  FlushToken(): void {
    let SESSION = JSON.parse(this.GetCurrentSession() as string);
    SESSION['access_token'] = '';

    this.setCurrentSession(SESSION);
  }

}
