import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompaniasModel } from '../intefaces/companys';
import { AppConstants } from '../intefaces/constants';
import { BaseResponse, CompaniesResponse, GetSAPConnectionsResponse, GenericResponse } from '../intefaces/response';
import { DataStorageService } from './data-storage.service';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient, private dataStorageService: DataStorageService) { }

  // metodo que obtiene las companias registradas en la DB
  GetCompanies(OnlyActives: boolean) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.dataStorageService.GetToken()}`
    })
    return this.http.get<CompaniesResponse>(`${environment.ApiUrl}api/Company/GetCompanies?OnlyActives=${OnlyActives}`, { headers });
  }

  // metodo que obtiene listado de conexiones de SAP
  GetSapConnections() {
    return this.http.get<GetSAPConnectionsResponse>(`${environment.ApiUrl}api/Company/GetSapConnections`,{
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'Authorization': `Bearer ${this.dataStorageService.GetToken()}`
      })
    });
  }

  // metodo que crea un usuario en la DBLocal
  CreateCompany(CompanyObj: CompaniasModel) {
    return this.http.post<BaseResponse>(`${environment.ApiUrl}api/Company/CreateCompany`, CompanyObj,{
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'Authorization': `Bearer ${this.dataStorageService.GetToken()}`
      })
    });
  }

  // metodo que actualiza un usuario en la DBLocal
  UpdateCompany(CompanyObj: CompaniasModel) {
    return this.http.post<BaseResponse>(`${environment.ApiUrl}api/Company/UpdateCompany`, CompanyObj,{
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'Authorization': `Bearer ${this.dataStorageService.GetToken()}`
      })
    });
  }

  GetKeyReportManager() {
    return this.http.get<GenericResponse<number>>(`${environment.ApiUrl}api/Company/GetKeyReportManager`,{
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'Authorization': `Bearer ${this.dataStorageService.GetToken()}`
      })
    });
  }

}
