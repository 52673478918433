import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Credentials } from 'src/app/core/intefaces/auth';
import { ConfirmMessage } from 'src/app/core/intefaces/messages';
import { emailValidator } from 'src/app/theme/utils/app-validators';

@Component({
  selector: 'app-credential-dialog',
  templateUrl: './credential-dialog.component.html',
  styleUrls: ['./credential-dialog.component.css']
})
export class CredentialDialogComponent implements OnInit {

  sms: string = ''; // mensaje de la modal
  title: string = ''; // titulo del modal

  // form de busqueda de ordenes de compra
  credentialForm!: FormGroup;


  constructor(public dialogRef: MatDialogRef<CredentialDialogComponent>,
    public formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public message: ConfirmMessage) { }

  ngOnInit(): void {
    this.onLoad();
  }

  onLoad() {
    this.title = this.message.title;
    this.sms = this.message.sms;
    this.credentialForm = this.CreateCredentialForm();
  }

  CreateCredentialForm() {
    return this.formBuilder.group({
      Email: ['', Validators.compose([Validators.required, emailValidator])],
      Password: ['', Validators.required],
    });
  }

  sendCredentials(cred: Credentials) {
    this.dialogRef.close(cred);
  }
}
