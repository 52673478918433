<h1 mat-dialog-title>{{title}}</h1>
<div mat-dialog-content>
    <p>{{sms}}</p>
    <div>
        <form [formGroup]="credentialForm" fxLayout="row wrap" fxFlexFill>
            <div fxFlex="100" fxFlex.gt-sm="50" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
                <mat-form-field appearance="fill"  class="w-100 mt-2">
                    <mat-label>Correo</mat-label>
                    <input matInput type="text" placeholder="Correo" formControlName="Email">
                    <mat-error *ngIf="credentialForm.controls.Email.errors?.required">El usuario es requerido</mat-error>
                    <mat-error *ngIf="credentialForm.controls.Email.hasError('invalidEmail')">El formato del correo no es valido ('ejemplo@ejemplo.com')
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="100" fxFlex.gt-sm="50" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
                <mat-form-field appearance="fill"  class="w-100 mt-2">
                    <mat-label>Contraseña</mat-label>
                    <input matInput type="password" placeholder="Contraseña" formControlName="Password">
                    <mat-error *ngIf="credentialForm.controls.Password.errors?.required">La Contraseña es requerida</mat-error>
                </mat-form-field>
            </div>
        </form>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="null">Cancelar</button>
    <button mat-button (click)="sendCredentials(this.credentialForm.value)" [disabled]="!credentialForm.valid">Aceptar</button>
    <!-- <button mat-button [mat-dialog-close]="true" [disabled]="!credentialForm.valid">Aceptar</button> -->
</div>