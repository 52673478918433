import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConstants } from './../intefaces/constants';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DataStorageService } from './data-storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PermsModel } from '../intefaces/perms';
import { Credentials } from '../intefaces/auth';
import { PermissionService } from './permission.service';
import { IToken } from '../intefaces/i-token';
import { SidebarService } from './sidebar.service';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  isSidebarVisible: boolean = false;
  sidebarVisibilityChange: Subject<boolean> = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private dataStorageService: DataStorageService,
    private router: Router,
    public snackBar: MatSnackBar,
    private permissionService: PermissionService,
    private sidebarService: SidebarService
  ) {
    console.log('authservice');
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('currentUser') as string)
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  toggleSidebarVisibility() {
    this.sidebarVisibilityChange.next(!this.isSidebarVisible);
  }
  // funcion para el logueo del usuario,
  // recibe como parametros el userId
  logIn(userId: string) {
    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('UserName', userId);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.post<any>(
      `${environment.ApiUrl}token`,
      body.toString(),
      { headers }
    );
  }

  logout() {
    this.toggleSidebarVisibility();
    // this.sidebarService.toggler.next(false)
    this.dataStorageService.deleteStorage('currentUser');
    this.dataStorageService.deleteStorage('HTHPerms');
    this.dataStorageService.deleteStorage('HTHSelectedCompany');
    this.router.navigate(['']);
  }

  cantAccess(path: string) {
    this.router.navigate([path]);
  }

  // Verificacion si un usuario esta logueado y el token no ha expirado aun
  public isAuthenticated(): boolean {
    // Check whether the token is expired and return
    // true or false
    // const TOKEN = this.currentUserValue;
    const TOKEN = JSON.parse(
      this.dataStorageService.getStorage('currentUser')
    );

    if (TOKEN) {
      var expires = new Date(TOKEN.ExpireTime);
      var today = new Date();
      if (today <= expires) {
        // posee token y el mismo aun no expira, es valido
        return true;
      }
    }
    return false;
  }

  havePerm(perm: string): boolean {
    let currentPerms = JSON.parse(
      this.dataStorageService.getStorage('HTHPerms')
    );

    let havePerm = this.dataStorageService.GetPermissionCurrentSession().filter((x: PermsModel) => {
      return x.PermName === perm;
    });

    if (havePerm.length > 0) {
      // tiene permiso para ingresar a la pagina deseada
      return true;
    } else {
      // no tiene permiso para ingresar a la pagina deseada
      return false;
    }
  }

  haveSelectedCompany(): boolean {
    let selectedCompany = JSON.parse(
      this.dataStorageService.getStorage('HTHSelectedCompany')
    );
    if (selectedCompany) {
      return true;
    } else {
      return false;
    }
  }

  // verificar usuario ingresado cuando se va a crear el lote
  CheckUser(Email: string, PassWord: string) {
    let Credentials: Credentials = {
      Email: Email,
      Password: PassWord,
    };
    return this.http.post<boolean>(
      `${environment.ApiUrl}api/Auth/CheckUser`,
      Credentials
    );
  }

  // NUEVO LOGIN
  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public deleteCurrentUserValue(): any {
    this.currentUserSubject.next(null);
  }

  login(username: string, password: string) {
    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('username', username)
      .set('password', password);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    console.log('login2222');
    return this.http
      .post<IToken>(`${environment.ApiUrl}token`, body.toString(), {
        headers,
      })
      .pipe(
        map((user) => {
          if (user && user.access_token) {
            this.dataStorageService.setCurrentSession(user);
            this.currentUserSubject.next(user);
            this.permissionService
              .GetPermissionsByUser(+user.UserEmail)
              .subscribe(
                (next) => {
                  if (next.Result) {
                    this.dataStorageService.SetPermissions(next.Permissions);
                  } else {
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
          }

          return user;
        })
      );
  }

  Login(username: string, password: string): Observable<IToken> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('userName', username)
      .set('password', password);
    return this.http
      .post<IToken>(`${environment.ApiUrl}token`, body.toString(), {
        headers,
      })
      .pipe(
        map((token) => {
          if (token) {
            this.dataStorageService.setCurrentSession(token);
          }
          return token;
        })
      );
  }
}
