import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from '../intefaces/constants';
import { Permission } from '../intefaces/permission';
import { PermissionByRole } from '../intefaces/permissionByRole';
import { PermsInfo } from '../intefaces/perms';
import { GenericResponse, IPermissionsResponse, PermResponse } from '../intefaces/response';
import { Role } from '../intefaces/role';
import { DataStorageService } from './data-storage.service';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  constructor(private http: HttpClient, private data: DataStorageService) { }

  // metodo que obtiene los permisos
  GetPerms(UserId: string) {
    let PermsInfo: PermsInfo = {
      UserId: UserId
    };
    return this.http.post<PermResponse>(`${environment.ApiUrl}api/Perms/GetPerms`, PermsInfo
    ,{
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'Authorization': `Bearer ${this.data.GetToken()}`
      })
    });
  }

  GetPermissions(){
    return this.http.get<GenericResponse<Permission[]>>(`${environment.ApiUrl}api/Permission/GetPermissions`
    ,{
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'Authorization': `Bearer ${this.data.GetToken()}`
      })
    });
  }

  GetPermissionsByRole(role: Role){
    return this.http.get<GenericResponse<PermissionByRole[]>>(`${environment.ApiUrl}api/Permission/GetPermissionsByRole?roleId=${role.Id}`,
    {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'Authorization': `Bearer ${this.data.GetToken()}`
      })
    });
  }

  UpdatePermissionsByRole(permissionIds: number[], roleId: number){
    let params = {
      permissionsIds: permissionIds,
      roleId: roleId
    }
    return this.http.post<GenericResponse<PermissionByRole[]>>(`${environment.ApiUrl}api/Permission/UpdatePermissionsByRole`, params,
    {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'Authorization': `Bearer ${this.data.GetToken()}`
      })
    });
  }

  // NUEVO LOGIN
  GetPermissionsByUser(_IdUser: number): Observable<IPermissionsResponse> {
    const url = `${environment.ApiUrl}api/Permission/GetByUser?id=${_IdUser}`;

    return this.http.get<IPermissionsResponse>(url,{
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'Authorization': `Bearer ${this.data.GetToken()}`
      })
    });
  }

}
