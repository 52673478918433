import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivateChild,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Menu } from '../intefaces/menu';
import { AppService } from '../services/app.service';
import { AuthenticationService } from '../services/authentication.service';
import { DataStorageService } from '../services/data-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivateChild {
  menu!: Menu[]
  constructor(
    public auth: AuthenticationService,
    public snackBar: MatSnackBar,
    private appService: AppService
    ) {
      console.log('AuthGuard');
      this.appService.getMenu().subscribe({
        next: (callback) => {
          this.menu = callback;
        },
      });
    }


  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (route.url.length === 0) return true;

    if (!this.auth.isAuthenticated()) {
      this.auth.logout();
      return false;
    }

    if (route!.routeConfig!.path === 'Home') return true;

    if (route!.routeConfig!.path !== 'SelectCompany' && !this.auth.haveSelectedCompany()) {
      this.auth.cantAccess('SelectCompany');
      this.snackBar.open(`Debe de seleccionar una compañía para continuar, gracias!`,'×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      return false;
    }

    // validacion de permisos
    if (this.auth.havePerm('SU')) {
      // si tiene el permiso de super user lo deja pasar a todo lado
      return true;
    }
    if (route!.routeConfig!.path === 'SelectCompany' && !this.auth.havePerm('SelectCompany',)) {
      this.snackBar.open(`No tiene acceso a ${this.menu[1].name}`, '', {
        panelClass: ['notification-warning'], duration: 3000
      });
      this.auth.cantAccess('Home');
      return false;
    }
    if (
      route!.routeConfig!.path === 'SelectDocuments' && !this.auth.havePerm('SelectDocuments')) {
      this.snackBar.open(`No tiene acceso a ${this.menu[2].name}`, '', {
        panelClass: ['notification-warning'], duration: 3000
      });
      this.auth.cantAccess('Home');
      return false;
    }
    if (route!.routeConfig!.path === 'Lots' && !this.auth.havePerm('Lots')) {
      this.snackBar.open(`No tiene acceso a ${this.menu[3].name}`, '', {
        panelClass: ['notification-warning'], duration: 3000
      });
      this.auth.cantAccess('Home');
      return false;
    }
    if (
      route!.routeConfig!.path === 'Reports' && !this.auth.havePerm('Reports')) {

      this.snackBar.open(`No tiene acceso a ${this.menu[4].name}`,'', {
        panelClass: ['notification-warning'], duration: 3000
      });
      this.auth.cantAccess('Home');
      return false;
    }
    if ( route!.routeConfig!.path === 'ConfigCtaBanks' && !this.auth.havePerm('ConfigCtaBanks')) {
      this.snackBar.open(`No tiene acceso a ${this.menu[5].submenu![0].name}`, '', {
        panelClass: ['notification-warning'], duration: 3000
      });
      this.auth.cantAccess('Home');
      return false;
    }
    if (route!.routeConfig!.path === 'ConfigEmails' && !this.auth.havePerm('ConfigEmails')) {
      this.snackBar.open(`No tiene acceso a ${this.menu[5].submenu![1].name}`, '', {
        panelClass: ['notification-warning'], duration: 3000
      })
      this.auth.cantAccess('Home');
      return false;
    }
    if (
      route!.routeConfig!.path === 'ConfigCompany' && !this.auth.havePerm('ConfigCompany')) {
      this.snackBar.open(`No tiene acceso a ${this.menu[5].submenu![2].name}`, '', {
        panelClass: ['notification-warning'], duration: 3000
      });
      this.auth.cantAccess('Home');
      return false;
    }
    if (
      route!.routeConfig!.path === 'ConfigPerms' && !this.auth.havePerm('ConfigPerms')) {
      this.snackBar.open(`No tiene acceso a ${this.menu[5].submenu![3].name}`, '', {
        panelClass: ['notification-warning'], duration: 3000
      });
      this.auth.cantAccess('Home');
      return false;
    }
    if (
      route!.routeConfig!.path === 'SAPCredentials' && !this.auth.havePerm('ConfigUsers')) {
      this.snackBar.open(`No tiene acceso a ${this.menu[5].submenu![4].name}`, '', {
        panelClass: ['notification-warning'], duration: 3000
      });
      this.auth.cantAccess('Home');
      return false;
    }
    if (route!.routeConfig!.path === 'SearchPruchaseOrders' && !this.auth.havePerm('SearchPruchaseOrders')) {
      this.snackBar.open(`No tiene acceso a ${'SearchPruchaseOrders'}`, '', {
        panelClass: ['notification-warning'], duration: 3000
      });
      this.auth.cantAccess('Home');
      return false;
    }

    return true;
  }
}
