import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ParameterOption } from "src/app/core/intefaces/rpt-manager/i-parameter-option";

@Component({
  selector: 'app-mant-parameter-option',
  templateUrl: './mant-parameter-option.component.html',
  styleUrls: ['./mant-parameter-option.component.css']
})
export class MantParameterOptionComponent implements OnInit {
  parameterValueForm: FormGroup;
  parameterOptionsModal: ParameterOption[] = [];

  constructor(
    public dialogRef: MatDialogRef<MantParameterOptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    this.parameterValueForm = this.formBuilder.group({
      Key: ["", Validators.required],
      Value: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.onLoad()
  }

  onLoad() {

    console.log(this.data);
    this.resetParameterValueForm();

    if (this.data !== null && this.data?._parameterOptionsModal !== null && this.data?._parameterOptionsModal.length > 0) {
      console.log('trae data');
      this.parameterOptionsModal = this.data._parameterOptionsModal;
      // this.parameterOptionsModal = JSON.parse(JSON.stringify(this.data._parameterOptionsModal));
    }
  }
  resetParameterValueForm() {
    this.parameterValueForm.reset({
      Key: "",
      Value: "",
    });
  }

  onClickAddParameterValidValue() {
    this.parameterOptionsModal.push({
      ...this.parameterValueForm.value,
      Id: 0,
      ParameterId: 0,
    });
    this.resetParameterValueForm();
  }

  onClickDeleteValidValue(index: number) {
    if (this.parameterOptionsModal.length === 1) {
      // this.alertService.infoInfoAlert(
      //   "Debes agregar al menos una opción válida"
      // );
      return;
    }

    this.parameterOptionsModal.splice(index, 1);
  }

  // createPermission() {
  //   //this.blockUI.start("Procesando...");
  //   let permission: PermissionsModel = {
  //     Active: true,
  //     Description: this.permissionForm.get("Description").value,
  //     Id: 0,
  //     Name: this.permissionForm.get("Name").value,
  //     Type: this.permissionForm.get("Type").value,
  //   };

  //   this.permissionService
  //     .PostPermission(permission)
  //     .pipe(first())
  //     .subscribe(
  //       (response) => {
  //         this.blockUI.stop();

  //         if (response.result) {
  //           this.dismissModal(true);
  //           this.alertService.successInfoAlert(
  //             "Proceso finalizado exitosamente"
  //           );
  //           this.getPermissions();
  //         } else {
  //           this.alertService.errorAlert(response.errorInfo.Message);
  //         }
  //       },
  //       (err) => {
  //         this.blockUI.stop();
  //         this.alertService.errorAlert(err);
  //       }
  //     );
  // }

}
