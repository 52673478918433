import {environment} from "../../../environments/environment";

export class AppConstants {
  // static API_URL: string = environment.ApiUrl
  // API DEBUG
  // static API_URL: string = 'http://localhost:63232/';
  // static UI_URL: string = 'http://localhost:4210/#/';
  // API TEST
  // static API_URL: string = 'https://pruebatls2.clavisco.com/';
  // static UI_URL: string = 'https://pruebatls.clavisco.com/#/';
  // static API_URL: string = 'https://gvhthapitest.clavisco.com/';
  // static UI_URL: string = 'https://gvhthtest.clavisco.com/#/';

  public static get RPTMANAGER_URL(): string {
    return environment.RptManager_Url;
    // return"https://rptmngapiv2.clavisco.com/";
    // return "https://rptmanagertest.clavisco.com/";
    // return "https://rptmanagerdev.clavisco.com/";
    // return "https://rptmanager.clavisco.com/";
    // return "http://localhost:50050/";
  }

  public static KeyReportManager = environment.KeyReportManager; //number = 37;
  public static Company =  environment.Company; //number = 34;
}
export enum DocumentTypes {
  GoodReceipt = 2,
  PurchaseOrder = 1
}
export interface EnumList {
  id: number;
  name: string;
}
